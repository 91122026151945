@tailwind base;
@tailwind components;
@tailwind utilities;

@import "rsuite/dist/rsuite.css";

a:hover {
	text-decoration: none;
	outline: none !important;
}
a:focus {
	text-decoration: none;
	outline: none !important;
}

.inter-medium {
	font-family: "Inter", sans-serif;
	font-weight: 500;
	font-style: normal;
}

.inter-normal {
	font-family: "Inter", sans-serif;
	font-weight: 400;
	font-style: normal;
}
.inter-600 {
	font-family: "Inter", sans-serif;
	font-weight: 600;
	font-style: normal;
}
.inter-700 {
	font-family: "Inter", sans-serif;
	font-weight: 700;
	font-style: normal;
}

.custom-blue-bg {
	background-color: var(--custom-blue);
}
.custom-blue-text {
	color: var(--custom-blue);
}

.custom-blue-border {
	border: 1px solid var(--custom-blue);
}

.custom-gray10,
&:hover,
&:focus {
	color: var(--custom-gray10) !important;
}

.custom-gray05 {
	border: 1px solid var(--custom-gray05);
}
.custom-border {
	border: 1px solid var(--custom-border);
}

.custom-gray06 {
	color: var(--custom-gray06);
}
.line-height-22 {
	line-height: var(--custom-line-height);
}

.btn-bg {
	background-color: var(--custom-blue);
}

.connectwithus {
	box-shadow: 0px 0px 10.8px 2px #0000000d;
}

.desk-shadow {
	box-shadow: 0px 8px 32px 2px #0c111d14;
}
.mobnav-shadow {
	box-shadow: 0px 4px 16px 2px #0c111d0f;
}

.contact-inputBox-border {
	border: 1px solid var(--custom-contact-inputBox-border);
}

.checkbox-container {
	height: 168px;
	display: flex;
	flex-direction: column;
	gap: 24px;
	width: 303px;
}

.checkbox-container input[type="checkbox"]:checked + label::before {
	content: "\2713";
	color: white;
	display: flex;
	justify-content: center;
	align-items: center;

	font-size: 16px;
	font-weight: bold;
	rotate: 10deg;
}

.checkbox-container label::before {
	content: "";
	display: block;
	width: 16px;
	height: 16px;
	border-radius: 50%;
	border: 5px solid var(--custom-blue);
	background: transparent;
	transition: 0.3s, border-color 0.3s;
}

.checkbox-container input[type="checkbox"]:checked + label::before {
	background-color: #00ba4a;
	border-color: transparent;
	box-shadow: 0px 0px 0px 2px #00ba4a33;
	width: 16px;
	height: 16px;
}

input[type="checkbox"]:checked + span {
	background-color: #38a169;
}

input[type="checkbox"]:checked + span::before {
	content: "";
	display: block;
	position: relative;
	width: 0.75rem;
	height: 0.75rem;
	border: solid white;
	border-width: 0 2px 2px 0;
	transform: rotate(45deg);
	top: 0.15rem;
	left: 0.15rem;
}

.markSlider {
	width: 25%;
	height: 4px;
	border-radius: 2px;
	background-color: var(--custom-blue);
}

.markSliderInactive {
	width: 25%;
	height: 4px;
	background-color: var(--custom-contact-inputBox-border);
}

.contact-form-shadow {
	box-shadow: 0px 0px 0px 2px #006dba33;
}

.contact-form .form-field:focus-within {
	border: 1px solid var(--custom-fileds-border);
	box-shadow: 0px 0px 0px 2px #006dba33;
}

.input-fileds-border-color {
	border: 1px solid var(--custom-fileds-border);
}

p {
	margin-top: 0 !important;
}

.custom-gray09 {
	color: var(--custom-gray09);
}

.remove-arrow::-webkit-inner-spin-button,
.remove-arrow::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

.summary-border {
	border: 1px solid var(--custom-summary-border);
}

.custom-gray07 {
	color: var(--custom-gray07);
}
.custom-blue01 {
	background-color: var(--custom-blue01);
}

.custom-border-bottom {
	border-top: none;
	border-left: none;
	border-right: none;
	border-bottom: dashed;
	border-color: var(--custom-gray05);
	border-width: 1px;
}

.custom-gray04 {
	border: 1px solid var(--custom-gray04);
}
.active {
	font-weight: bold;
	color: var(--custom-blue);
}

.slider-step {
	width: 25%;
	height: 4px;
	background-color: var(--custom-blue02);
	border-radius: 5px;
	position: relative;
	flex-grow: 1;
}

.slider-step.completed {
	background-color: var(--custom-blue);
	width: 25%;
	height: 4px;
}

.custom-blue02 {
	border: 1px solid var(--custom-blue02);
}
.step2 button {
	border: 1px solid var(--custom-blue02);
	color: var(--custom-gray07);
}
.step2 button.hover {
	color: var(--custom-blue04);
}
.step2 button.selected {
	color: var(--custom-blue04);
	background-color: white;
	border: 1px solid var(--custom-blue04);
}

.step4 button.hover {
	color: var(--custom-blue04);
}
.step4 button {
	border: 1px solid var(--custom-blue02);
	color: var(--custom-gray07);
}
.step4 button.selected {
	color: var(--custom-blue04);
	background-color: var(--custom-blue01);
	border: 1px solid var(--custom-blue04);
	box-shadow: 0px 0px 0px 2px #006dba33;
}

.custom-blue04 {
	color: var(--custom-blue04);
}
.popular-button {
	position: relative;
	padding: 10px 20px;
	border: none;
	background: #fff;
	color: #333;
	font-size: 16px;
	cursor: pointer;
	overflow: hidden;
	transition: background 0.3s ease;
}

.popular-button::before {
	content: "";
	position: absolute;
	top: -33px;
	right: -21px;
	height: 60px;
	width: 92px;
	background: var(--custom-blue);
	transition: width 0.3s ease;
	rotate: 30deg;
}

.step2 .badge {
	position: absolute;
	height: 0;
	width: 0;
	right: 25px;
	top: 18px;
	z-index: 1;
	display: flex;
	align-items: center;
	justify-content: center;
	rotate: 30deg;
}
.step4 .badge {
	position: absolute;
	height: 0;
	width: 0;
	right: 25px;
	top: 18px;
	z-index: 1;
	display: flex;
	align-items: center;
	justify-content: center;
	rotate: 30deg;
}

.calendarClock-wrapper {
	position: relative;
	border: 1px solid var(--custom-fileds-border);
	box-shadow: 0px 0px 0px 2px #006dba33;
	border-radius: 6px;
}

.calendar-container {
	position: absolute;
	bottom: 100%; /* Position above the input */
	left: -1px;
	z-index: 1000; /* Ensure it is on top of other elements */
	box-shadow: 0px 8px 32px 2px #0c111d14 !important;
}

.react-calendar {
	width: 342px !important;
	border-radius: 6px;
	border: none !important;
}

button.react-calendar__navigation__arrow.react-calendar__navigation__prev2-button,
button.react-calendar__navigation__arrow.react-calendar__navigation__next2-button {
	display: none;
}

.react-calendar__navigation {
	height: 60px !important;
	border-radius: 6px 6px 0px 0px;
	border-bottom: 1px solid var(--custom-border);
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0px 8px !important;
}

span.react-calendar__navigation__label__labelText.react-calendar__navigation__label__labelText--from {
	text-align: center;
	font-family: Inter;
	font-weight: 500;
	font-size: 20px;
	color: var(--custom-gray08);
	line-height: 22px;
}
.custom-gray08 {
	color: var(--custom-gray08);
}

.react-calendar__tile--now {
	border: 1px solid var(--custom-fileds-border) !important;
	border-radius: 50%;
	background-color: white !important;
	color: var(--custom-gray10);
}

.react-calendar__tile--active {
	background-color: var(--custom-blue) !important;
	border-radius: 50%;
	color: white !important;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
	background-color: white !important;
}

.react-calendar button {
	width: 32px !important;
	height: 32px !important;
	flex: none !important;
	justify-content: center;
	align-items: center;
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
	background: var(--custom-blue) !important;
}
abbr:where([title]) {
	text-decoration: none !important;
	line-height: 22px;
	font-size: 14px;
	font-weight: 400;
	font-family: Inter;
	color: var(--custom-gray10);
}
.react-calendar__viewContainer {
	padding: 8px 12px !important;
}

.bookingcontactform select {
	color: var(--custom-gray06);
	font-size: 14px;
	background: transparent !important;
	line-height: 22px;
	-moz-appearance: none;
	-webkit-appearance: none;
	-ms-appearance: none;
	-o-appearance: none;
	appearance: none;
	width: 100%;
}

.react-calendar__month-view__days {
	padding: 0px 6px !important;
	display: flex;
	flex-wrap: wrap;
	gap: 13px;
	justify-content: center;
	align-items: center;
}

.react-calendar__tile {
	padding: 0 !important;
	font-size: 14px !important;
	font-weight: 400px !important;
}

button.react-calendar__navigation__label {
	flex-grow: 1 !important;
}
.react-calendar button abbr {
	font-family: "Inter", sans-serif;
	font-weight: 400;
	line-height: 22px;
	text-align: center;
}
.react-calendar__month-view__weekdays,
.react-calendar__month-view__weekdays__weekday {
	font-family: "Inter", sans-serif;
	font-weight: 600;
}
.react-calendar__month-view__weekdays {
	display: flex;
	justify-content: space-between;
	align-items: center;
	text-align: center;
	margin-bottom: 12px;
}

.react-calendar__tile:disabled {
	font-family: "Inter", sans-serif;
	font-weight: 400;
	line-height: 22px;
	text-align: center;
	font-size: 14px !important;
	color: var(--custom-gray06);
	background-color: transparent !important;
}
.css-gqp4xl-MuiPickersLayout-root .MuiPickersLayout-actionBar {
	display: none;
}
