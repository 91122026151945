:root {
	--custom-blue: #006dba;
	--custom-box-shadow: 0px 0px 10.8px 2px #0000000d;
	--custom-line-height: 22px;
	--custom-border: #f0f0f0;
	--custom-contact-inputBox-border: #cfe0ff;
	--custom-fileds-border: #264ddb;
	--custom-summary-border: #126aee;
	--custom-blue01: #f0f6ff;
	--custom-gray04: #d9d9d9;
	--custom-gray05: #bfbfbf;
	--custom-gray06: #8c8c8c;
	--custom-gray07: #595959;
	--custom-gray08: #434343;
	--custom-gray09: #262626;
	--custom-gray10: #1f1f1f;
	--custom-blue04: #4D73E8;
	--custom-blue02: #CFE0FF;
}
